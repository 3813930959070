
import axios, { AxiosPromise, AxiosTransformer } from 'axios';

let ISO_8601 = /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})$/;
let transformDate = function (data: any) {
    if(data === undefined || data === null)
    {
        return null;
    }

    Object.keys(data).forEach(function (k) {
        if(data[k] instanceof Object) {
            transformDate(data[k]);
        } else {
            if (ISO_8601.test(data[k])) {
                data[k] = new Date(Date.parse(data[k]));
            }
        }
    });
    return data;
};
let transformResponse = (axios.defaults.transformResponse as AxiosTransformer[]).concat(transformDate)


import { EditContactDetailsViewModel } from './viewmodels/EditContactDetailsViewModel'
import { ResendNotificationViewModel } from './viewmodels/ResendNotificationViewModel'
import { CustomsHandlingViewModel } from './viewmodels/CustomsHandlingViewModel'
import { SearchResultViewModel } from './viewmodels/SearchResultViewModel'


        
    // Search
    export function search (reference: string, customer: string) : AxiosPromise<SearchResultViewModel[]> {
        return axios({
            url: `api/CustomsHandling/search/?reference=${encodeURIComponent(reference)}&customer=${encodeURIComponent(customer)}`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // GetItemById
    export function getItemById (id: number) : AxiosPromise<CustomsHandlingViewModel> {
        return axios({
            url: `api/CustomsHandling/getItemById/${id}`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // EditContactDetails
    export function editContactDetails (model: EditContactDetailsViewModel) : AxiosPromise<any> {
        return axios({
            url: `api/CustomsHandling/editContactDetails/`, 
            method: "post",
            data: model,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // ResendNotification
    export function resendNotification (model: ResendNotificationViewModel) : AxiosPromise<any> {
        return axios({
            url: `api/CustomsHandling/resendNotification/`, 
            method: "post",
            data: model,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };

