import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AddressViewModel } from '../api/viewmodels/AddressViewModel';
import { CustomsHandlingViewModel } from '../api/viewmodels/CustomsHandlingViewModel';
import { MilestoneViewModel } from '../api/viewmodels/MilestoneViewModel';
import moment from 'moment';

import * as CustomsHandlingController from '@/api/CustomsHandlingController';
import * as DocumentController from '@/api/DocumentController';
import { EditContactDetailsViewModel } from '../api/viewmodels/EditContactDetaisViewModel';
import { ResendNotificationViewModel } from '../api/viewmodels/ResendNotificationViewModel';

@Component({})
export default class CustomsHandlingItem extends Vue {
    @Prop()
    hawb!: string;
    @Prop()
    id!: number;
    @Prop()
    opened!: boolean;

    phoneCopy: string = '';
    emailCopy: string = '';

    updatedContactDetailsLoading: boolean = false;
    updatedContactDetailsSuccess: boolean = false;
    updatedContactDetailsFailed: boolean = false;

    resendNotificationLoading: boolean = false;
    resendNotificationSuccess: boolean = false;
    resendNotificationFailed: boolean = false;

    loading: boolean = false;

    item: CustomsHandlingViewModel | null = null;

    @Watch('opened')
    onOpenedChange() {
        if (this.opened) {
            this.loadItem();
        }
    }

    mounted() {
        if (this.opened) {
            this.loadItem();
        }
    }

    loadItem() {
        this.loading = true;
        CustomsHandlingController.getItemById(this.id)
            .then(response => {
                if (response.data !== undefined) {
                    this.item = response.data;
                }

                this.loading = false;
            });
    }

    downloadDocument(documentId: number, documentName: string) {
        DocumentController.download(documentId).then(result => {
            if (result.data.size === 0) {
                return;
            }
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', documentName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
    }

    dateFromDateTimeString(dateTimeString: string) {
        return moment(dateTimeString).format('DD-MM-YYYY');
    }

    timeFromDateTimeString(dateTimeString: string) {
        return moment(dateTimeString).format('HH:mm');
    }

    copyHawbToClipboard() {
        let hawb = this.$refs.hawb as HTMLInputElement;
        let tempInput = document.createElement("input");
        tempInput.style.position = "absolute";
        tempInput.style.left = "-10000px";
        tempInput.style.top = "-10000px";
        tempInput.value = hawb.value;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
    }

    save(type: string) {
        if (type === 'phone') {
            this.phoneCopy = '';
        }
        else {
            this.emailCopy = '';
        }
    }

    open(type: string) {
        if (type === 'phone') {
            this.phoneCopy = this.item!.deliveryAddress.contactPhone;
        }
        else {
            this.emailCopy = this.item!.deliveryAddress.contactEmail;
        }
    }

    cancel(type: string) {
        if (type === 'phone') {
            this.item!.deliveryAddress.contactPhone = this.phoneCopy;
        }
        else {
            this.item!.deliveryAddress.contactEmail = this.emailCopy;
        }
    }

    updateContactDetails() {
        this.updatedContactDetailsLoading = true;
        let model: EditContactDetailsViewModel = {
            hawb: this.item!.hawb,
            emailAddress: this.item!.deliveryAddress.contactEmail,
            phoneNumber: this.item!.deliveryAddress.contactPhone
        };
        CustomsHandlingController.editContactDetails(model).then(result => {
            this.updatedContactDetailsLoading = false;
            this.updatedContactDetailsSuccess = true;
        }).catch(error => {
            this.updatedContactDetailsLoading = false;
            this.updatedContactDetailsFailed = true;
        })
    }

    resendNotification() {
        this.resendNotificationLoading = true;
        let model: ResendNotificationViewModel = {
            id: this.item!.id
        };
        CustomsHandlingController.resendNotification(model).then(result => {
            this.resendNotificationLoading = false;
            this.resendNotificationSuccess = true;
        }).catch(error => {
            this.resendNotificationLoading = false;
            this.resendNotificationFailed = true;
        })
    }
}