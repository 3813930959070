import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

interface Settings {
    customerAlias: string;
    customerName: string;
    customerId: number;
}


export default new Vuex.Store({
    state: {
        loggedIn: false,
        settings: {

        } as Settings
    },
    mutations: {
        setLoggedIn(state) {
            state.loggedIn = true
        },
        setLoggedOut(state) {
            state.loggedIn = false
        },
        setSettings(state, settings) {
            state.settings = settings;
        }
    },
    actions: {
        retrieveSettings: async ({ commit }) => {
            const response = await axios.get("/config.json");
            commit('setSettings', response.data);
        }
    },
    getters: {
        settings: state => state.settings,
        isNovaPost: state => state.settings.customerAlias === 'NovaPost',
        isDpd: state => state.settings.customerAlias === 'DPD',
    }
})