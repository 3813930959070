import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './scss/app.scss'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import './plugins/axios'

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
extend('required', required);

Vue.config.productionTip = false

store.dispatch('retrieveSettings')

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')