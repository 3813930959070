import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue.html'
import Login from '../views/Login.vue.html'
import store from '../store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { loggedIn: true }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
    const requiresLogin = to.matched.some((route) => route.meta.loggedIn);

    if (requiresLogin && !store.state.loggedIn) {
        // Gebruiker is nog niet ingelogd:
        next({
            path: '/login',
            query: {
                next: to.path
            }
        })
        return;
    }

    next();
})

export default router
